const projectsData = [
    {
      name: 'Crypto Exchange Platform',
      description: 'Built a scalable exchange with a real-time UI.',
      link: '#'
    },
    // Add more projects here
  ];
  
  export default projectsData;
  
import React from 'react';
import projectsData from '../data/projectsData';

export default function Projects() {
  return (
    <section id="projects" className="py-16 bg-gray-800">
      <h2 className="text-4xl font-semibold text-center mb-8">Projects</h2>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 px-4">
        {projectsData.map((project, index) => (
          <div key={index} className="bg-gray-700 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-4">{project.name}</h3>
            <p className="mb-4">{project.description}</p>
            <a
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-500 hover:underline"
            >
              View Project
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}

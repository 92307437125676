import React from 'react';
import Navbar from './components/Navbar';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';

export default function App() {
  return (
    <div className="bg-gray-900 text-white font-sans">
      <Navbar />
      <main className="px-4 md:px-12 lg:px-20">
        <About />
        <Projects />
        <Skills />
        <Contact />
      </main>
      <h>HELLO</h>
    </div>
  );
}

import React from 'react';

export default function Contact() {
  return (
    <section id="contact" className="py-16 bg-gray-800">
      <h2 className="text-4xl font-semibold text-center mb-8">Contact Me</h2>
      <form className="max-w-xl mx-auto space-y-6">
        <input
          type="text"
          placeholder="Your Name"
          className="w-full p-4 rounded-lg bg-gray-700 text-white focus:outline-none"
        />
        <input
          type="email"
          placeholder="Your Email"
          className="w-full p-4 rounded-lg bg-gray-700 text-white focus:outline-none"
        />
        <textarea
          placeholder="Your Message"
          rows="5"
          className="w-full p-4 rounded-lg bg-gray-700 text-white focus:outline-none"
        />
        <button
          type="submit"
          className="w-full py-3 bg-yellow-500 text-white font-semibold rounded-lg hover:bg-yellow-600"
        >
          Send Message
        </button>
      </form>
    </section>
  );
}

import React from 'react';

export default function Skills() {
  return (
    <section id="skills" className="py-16">
      <h2 className="text-4xl font-semibold text-center mb-8">Skills</h2>
      <div className="flex flex-wrap justify-center space-x-4 space-y-4 text-center">
        {['React.js', 'Tailwind CSS', 'Node.js', 'Docker', 'AWS'].map((skill, index) => (
          <div
            key={index}
            className="bg-gray-700 px-6 py-3 rounded-lg shadow-lg text-lg font-medium"
          >
            {skill}
          </div>
        ))}
      </div>
    </section>
  );
}

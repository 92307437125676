import React from 'react';

export default function Navbar() {
  return (
    <nav className="flex justify-between items-center py-6 bg-gray-800 px-6 shadow-lg">
      <h1 className="text-3xl font-bold text-white">Abhiram Veladineni</h1>
      <ul className="flex space-x-8 text-lg">
        <li><a href="#about" className="hover:text-yellow-500">About</a></li>
        <li><a href="#projects" className="hover:text-yellow-500">Projects</a></li>
        <li><a href="#skills" className="hover:text-yellow-500">Skills</a></li>
        <li><a href="#contact" className="hover:text-yellow-500">Contact</a></li>
      </ul>
    </nav>
  );
}

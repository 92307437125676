import React from 'react';

export default function About() {
  return (
    <section id="about" className="py-16">
      <h2 className="text-4xl font-semibold text-center mb-8">About Me</h2>
      <p className="text-lg text-center max-w-2xl mx-auto leading-relaxed">
        Hi, I'm Abhiram, a passionate software developer focused on building scalable and efficient applications. With a background in Electrical Engineering and experience in full-stack development, I love solving complex problems.
      </p>
    </section>
  );
}
